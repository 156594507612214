.telefono{
    font-size: 3.75vw;
    color: red;
    width: 100%;
    text-align: center;
    }

.mail{
    font-size: 3.75vw;
    color: red;
    width: 100%;
    text-align: center;
    }

.error-post{
    font-size: 3.75vw;
    color: red;
    width: 100%;
    text-align: center;
}

.name{
    font-size: 3.75vw;
    color: red;
    width: 100%;
    text-align: center;
}

@media screen and (min-width: 1001px) {
    .telefono{
        font-size: 1.35vw;
        color: red;
        width: 100%;
        text-align: center;
    }

    .mail{
        font-size: 1.35vw;
        color: red;
        width: 100%;
        text-align: center;
    }

    .name{
        font-size: 1.35vw;
        color: red;
        width: 100%;
        text-align: center;
    }

    .error-post{
        font-size: 1.35vw;
        color: red;
        width: 100%;
        text-align: center;
    }
}