.contact-us{
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    width: 100%;
    height: 100%;
    padding: 5% 7% 10% 7%;
}

.contact-us-container{
    background-color: #273755;
    border-radius: 22px  ;
}

.contact-us .contact-us-container .contact-us-container-title{
    font-family: 'Montserrat';
    padding: 7% 0% 5% 0%;
    margin: 0% 10% 0% 10%;
    font-size: 0.8rem;
    color: #ffffff;
    line-height: 1.3;
    font-weight: 800;
}

.contact-us .form-contact{
    margin: 0% 5% 0% 5%;
}

.contact-us input{
    border-radius: 7px;
    margin: 0% 0% 4% 0%;
    height: 12vw;
    padding-left: 5%;
    color: black;
    border-color: #9F9F9F;
    border-width: 1px;
    font-size: 4.5vw;
}

.contact-us .input-name{
    width: 100%;
}

.contact-us .input-email{
    width: 100%;
}

.contact-us .input-phone-content .input-phone{
    width: 100%;
}

.contact-us label{
    color: #ffffff;
    font-family: 'Montserrat';
    font-size: 4vw;
    font-weight: 500;
}

.contact-us button {
    background-color: #ffffff;
    font-family: 'Montserrat';
    color: #273755;
    margin: 1% 0% 0% 23%;
    margin-bottom: 9vw;
    font-weight: 900;
    width: 55%;
    border: 0;
    border-radius: 7px;
    height: 10vw;
    font-size: 4vw;
  }

  .contact-us button span{
    width: 11%;
    height: 45%;
    margin: 0% 3% -1% 0%;
  }

  .contact-us .checkGreen {
    display: block;
    text-align: center;
    align-items: center;
    margin-top: 0px;
  }
  
  .contact-us .checkGreen-container{
    min-width: 100px;
    min-height: 100px;
  }

  #msform select {
    border-radius: 7px;
    margin: 0% 0% 4% 0%;
    height: 12vw;
    padding-left: 5%;
    color: black;
    border-color: #9F9F9F;
    border-width: 1px;
    font-size: 4.5vw;
    width: 100%;
}

  @media screen and (min-width: 281px) {

    .contact-us .contact-us-container .contact-us-container-title{
        font-size: 1rem;
    }

    .contact-us button {
      font-size: 4vw;
    }

    .contact-us .checkGreen-container{
        min-width: 150px;
        min-height: 150px;
      }
  }

  @media screen and (min-width: 361px) {
    .contact-us .contact-us-container .contact-us-container-title{
        font-size: 1.1rem;
    }
  }

  @media screen and (min-width: 768px) {
    .contact-us .contact-us-container .contact-us-container-title{
        font-size: 1.7rem;
    }
  }

  @media screen and (min-width: 1001px) {
    #msform select {
      border-radius: 7px;
      margin: 0% 0% 4% 0%;
      height: 3.75vw;
      padding-left: 5%;
      color: black;
      border-color: #9F9F9F;
      border-width: 1px;
      font-size: 1.5vw;
  }

  .dropdown-container{
      display: flex;
  }
    
    .contact-us{
        width: 100%;
        height: 100%;
        padding: 38% 27% 5% 27%;
    }
    
    .contact-us .contact-us-container .contact-us-container-title{
        padding: 7% 0% 5% 0%;
        margin: 0% 7% 0% 7%;
        font-size: 2.45rem;
        line-height: 1.1;
    }
    
    .contact-us .form-contact{
        margin: 0% 5% 0% 5%;
    }
    
    .contact-us input{
        border-radius: 7px;
        margin: 0% 0% 4% 0%;
        height: 3.75vw;
        padding-left: 5%;
        color: black;
        border-color: #9F9F9F;
        border-width: 1px;
        font-size: 1.5vw;
    }
    
    .contact-us label{
        color: #ffffff;
        font-family: 'Montserrat';
        font-size: 1.25vw;
        font-weight: 500;
    }
    
    .contact-us button {
        background-color: #ffffff;
        font-family: 'Montserrat';
        color: #273755;
        margin: 1% 0% 0% 30%;
        margin-bottom: 4vw;
        font-weight: 900;
        width: 40%;
        border: 0;
        border-radius: 7px;
        height: 4vw;
        font-size: 1.75vw;
      }
    
      .contact-us button span{
        margin: 0% 3% -1% 0%;
      }

      .contact-us button span{
        width: 12%;
        height: 50%;
        margin: 0% 3% -1% 0%;
      }
    
      .contact-us .checkGreen {
        display: block;
        text-align: center;
        align-items: center;
        margin-top: 0px;
      }
      
      .contact-us .checkGreen-container{
        min-width: 150px;
        min-height: 150px;
      }
  }