input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}

.fullPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 26vw;
  font-family: "Roboto", "Poppins", sans-serif;
  background-color: #ffffff;
}

.fullPage .top-bar {
  display: grid;
  background-color: #273755;
  grid-template-rows: 1fr;
  transition: 0.4s;
  position: absolute;
  width: 100%;
  z-index: 99;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25%));
  padding-bottom: 4vw;
}

.slider-position {
  grid-column: 1/1;
  grid-row: 2/2;
}

.contact-us-position {
  grid-column: 1;
  grid-row: 3/3;
  background-color: #ffffff;
}

.smart-security-position {
  grid-column: 1;
  grid-row: 4/4;
  background-color: #273755;
}

.footer-position {
  grid-column: 1;
  grid-row: 5/5;
  background: #D9D9D9
}

@media screen and (min-width: 1001px) {
  .fullPage {
    grid-template-columns: 40% 60%;
    grid-template-rows: 8vw 8vw;
    background-position: 0vw 40vw;
    background-size: 100vw 26vw;
    background-color: #ffffff;
  }

  .fullPage .top-bar {
    transition: 0.4s;
    position: absolute;
    width: 100%;
    z-index: 99;
    overflow: hidden;
    grid-column: 1/3;
    display: grid;
    grid-template-rows: 1fr;
    grid-row: 1/1;
    padding-bottom: 1vw;
  }

  .slider-position {
    grid-column: 1/3;
    grid-row: 2/2;
    /* justify-self: center; */
  }

  .contact-us-position {
    grid-column: 1/3;
    grid-row: 3/3;
  }

  .smart-security-position {
    grid-column: 1/3;
    grid-row: 4/4;
  }

  .footer-position {
    grid-column: 1/3;
    grid-row: 5/5;
  }

}
