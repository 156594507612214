.logo {
    display: block;
    width: max-content;
    z-index: 999999;
    justify-self: left;
    grid-column: 1/2;
    grid-row: auto;
    margin-top: 4vw;
    /* padding: 1.5vw; */
    padding-left: 7vw;
  }
  
  .logo img {
    margin: auto;
    width: 12vw;
  }
  
  @media screen and (min-width: 1001px) {
    .logo {
      padding: 0vw;
      padding-left: 5vw;
      margin-top: 1vw;
    }
  
    .logo img {
      display: block;
      width: 4.5vw;
    }
  }
  