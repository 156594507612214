.smart-security{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    align-items: center;
}

.smart-security-content{
    margin: 10% 0% 20% 0%;
}

.smart-security-img-position{
    margin: 0% 10% 0% 10%;
}

.smart-security-img-position .smartapp-image{
    padding-top: 5vw;
    width: 70%;
    height: 100%;
    margin: 0% 18% 5% 18%;
}

.smart-security-img-position .smartset-image{
    padding-top: 5vw;
    width: 100%;
    height: 100%;
    margin: 10% 0% 10% 0%;
}

.smart-security-content .smart-security-position{
    background-color: #ffffff;
    padding: 7%;
    border-radius: 18px;
    color: #273755;
    font-family: 'Montserrat';
}

.smart-security-content .smart-security-position h1{
    font-size: 5.3vw;
    font-weight: 900;
}

.smart-security-content .smart-security-position h3{
    font-size: 3.75vw;
    font-weight: 500;
}

.smart-security-content .smart-security-position h4{
    font-size: 2.75vw;
    font-weight: 500;
}

.smart-security-content .smart-security-position li{
    font-size: 2.5vw;
    font-weight: 400;
}

@media screen and (min-width: 1001px) {
    .smart-security-title{
        padding-top: 5%;
        text-align: center;
        font-weight: 600;
        font-size: 3.5rem;
    }

    .smart-security-img-position{
        display: flex;
        width: 85%;
        margin: 0% 0% 0% 0%;
    }
    
    .smart-security-content{
        margin: 7% 0% 7% 0%;
    }
    
    .smart-security-img-position .smartapp-image{
        padding-top: 5vw;
        width: 30%;
        height: 100%;
        margin: 7% 18% 5% 18%;
    }
    
    .smart-security-img-position .smartset-image{
        padding-top: 5vw;
        width: 40%;
        height: 100%;
        margin: 7% 13% 5% 13%;
    }
    
    .smart-security-content .smart-security-position{
        background-color: #ffffff;
        padding: 5% 3% 1% 3%;
        margin: 0% -5% 0% 0%;
        width: 100%;
    }
    
    .smart-security-content .smart-security-position h1{
        font-size: 2.15vw;
    }
    
    .smart-security-content .smart-security-position h3{
        font-size: 1.45vw;
    }
    
    .smart-security-content .smart-security-position h4{
        font-size: 1.15vw;
    }
    
    .smart-security-content .smart-security-position li{
        font-size: 1.25vw;
        padding-right: 2%;
    }
}