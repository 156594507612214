.slider-title {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.3fr 4fr;
  font-family: "Poppins", sans-serif;
}

.slider-title h2 {
  text-align: center;
  font-weight: 500;
  grid-column: 1/1;
  grid-row: 1/1;
  background: #1273b9;
  color: #ffffff;
  font-size: 4.5vw;
  padding-top: 9vw;
}

.slider-title h3 {
  text-align: center;
  font-size: 4vw;
  grid-column: 1/1;
  grid-row: 1/1;
  margin-top: 12vw;
  color: #000000;
  margin-bottom: 1vw;
}

.carousel {
  grid-column: 1/1;
  grid-row: 2/2;
  width: 100%;
  margin-top: -8.5vw;
}

@media screen and (min-width: 1001px) {
  .slider-title {
    grid-template-rows: none;
  }
  .slider-title h2 {
    display: none;
  }
  .slider-promos-title {
    display: none;
  }

  .slider-title h3 {
    margin-top: 2vw;
    font-size: 2vw;
    font-weight: bold;
    max-width: max-content;
    align-self: center;
    cursor: pointer;
  }

  .slider-title h3:hover {
    color: #305da9;
    transform: scale(1.1);
  }

  .carousel {
    width: 100%;
    align-self: center;
    margin-top: -1vw;
  }
}
