.footer{
    align-items: center;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    margin-top: 10%;
}

.footer-icons-position{
    margin: 5% 23% 0% 30%;
    display: flex;
    justify-content: space-around
}

.facebook-icon{
    width: 7.5vw;
    margin-left: 15%;
}

.phone-icon{
    width: 7.5vw;
}

.maps-icon{
    width: 7.5vw;
    margin-right: 15%;
}


.footer-content-position{
    padding: 5% 0% 5% 0%;
}

.footer-content-position h1{
    font-family: 'Montserrat';
    text-align: center;
    font-weight: 400;
    color: #273755;
    font-size: 0.9rem;
}

@media screen and (min-width: 1001px) {
    .footer{
        margin-top: 0%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }

    .footer-icons-position{
        margin: 0% 0% 0% 0%;
        width: 10%;
        display: flex;
        justify-content: space-around
    }
    
    .facebook-icon{
        width: 2.5vw;
        margin-left: 0%;
    }
    
    .phone-icon{
        width: 2.5vw;
    }
    
    .maps-icon{
        width: 2.5vw;
        margin-right: 0%;
    }
    
    .footer-content-position{
        padding: 4% 30% 4% 40%;
    }
    
    .footer-content-position h1{
        font-family: 'Montserrat';
        color: #273755;
        font-size: 1.5rem;
    }
}